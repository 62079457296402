/*
 *
 * HT DVEŘE
 * Author: Martin Winkler @ PORTA
 * url: http://www.portadesign.cz/
 *
 */

var sliders = [];

Dropzone.autoDiscover = false;

/****************************************************************************************************************************************************************************************
DOCUMENT READY
****************************************************************************************************************************************************************************************/

jQuery(document).ready(function ($) {

	// GTM
	$('.js-gtm-click').on('click', function () {
		window.dataLayer.push({
			event: "click_detail",
			store_name: $(this).data('gtm')
		});
	});

	/**********************************************
	VARIOUS
	**********************************************/

	// Scrollování ke kotvě
	$('.to-anchor').scrollToAnchor();

	// Scrollspy
	$('.scrollspy').scrollSpy();

	// Tabs
	$('.tabs').liteTabs();

	// Product detail image - css responsive hack
	$(window).on('load resize', function () {
		var height = $('.product-headline').height();

		$('.product-img').css({
			'margin-top': '-' + height + 'px'
		});
	});

	// Hints
	$('[data-hint]').hover(function () {
		var hint = $(this);
		var text = hint.attr('data-hint');

		hint.append('<span class="hint-popup">' + text + '</span>')
	}, function () {
		var hint = $(this);
		hint.find('.hint-popup').remove();
	});

	// Table css fix
	$('.entry table').wrap('<div class="table-wrap"></div>');
	$('.table-products').wrap('<div class="table-wrap"></div>');

	/**********************************************
	HEADER FIXED - MOBILE ONLY (< 575px)
	**********************************************/

	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('.header .h_2').outerHeight();

	$(window).scroll(function(event) {
	  didScroll = true;
	});

	setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
	}, 50);

	function hasScrolled() {
    var scrollTop = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollTop) <= delta) {
      return;
    }

    if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
      // Scroll Down
      $('.header').removeClass('nav-down').addClass('');
    } else {
      // Scroll Up
      if (scrollTop + $(window).height() < $(document).height()) {
        $('.header').removeClass('nav-up').addClass('nav-down');

        if (scrollTop < 50) {
		    	$('.header').removeClass('nav-down').removeClass('nav-up');
		    }
      }
    }

    lastScrollTop = scrollTop;
	}

	$(window).on('load scroll', function() {
		//var headerTop = $('.header .h_2').offset().top + $('.header .h_2').height();
		var headerTop = $('.header .h_2').offset().top;
		var scrollTop = $(window).scrollTop();

		if (scrollTop > headerTop) {
			$('.header').addClass('fixed');
		} else {
			$('.header').removeClass('fixed');
		}
	});

	/**********************************************
	BRANCH OFFICE(S) ACCORDION
	**********************************************/

	$('.regions .regions-item-head').on('click', function () {
		var item = $(this).closest('.regions-item');

		if (!item.hasClass('active')) {
			$('.regions-item').removeClass('active');

			item.addClass('active');

			var destination = item.offset().top - 5;

			$("html:not(:animated),body:not(:animated)").animate({
				scrollTop: destination
			}, {
				duration: 500,
				complete: function () {
					//history.pushState(null, null, elementClick);
				}
			});

		} else {
			item.removeClass('active');
		}
	});

	/**********************************************
	ADVISORY LIST(S) ACCORDION
	**********************************************/

	$('.advisory .advisory-controls .js-advisory-control').on('click', function () {
		var control = $(this);
		var documents = control.closest('.advisory-controls').siblings('.advisory-documents');
		if (!documents.hasClass('active')) {
			$('.advisory-documents').removeClass('active');
			documents.addClass('active');
			control.html(control.data('label-active'));
		} else {
			documents.removeClass('active');
			control.html(control.data('label'));
		}
	});

	/**********************************************
	SHOW/HIDE
	**********************************************/

	$('.sh-trig').toggler();

	$('.sh-trig-scroll').toggler({
		scrollToContent: true
	});

	$('.sh-trig-history').toggler({
		scrollToContent: true,
		historyPushState: true
	});

	if (window.location.hash) {
		$('.sh-trig-history').each(function() {
			if (window.location.hash == '#' + $(this).data('content')) {
				$(this).trigger('click');
			}
		});
	}

	/**********************************************
	FORM STYLING
	**********************************************/

	// Checkbox
	$('input[type="checkbox"]').each(function (index, element) {
		$(this).wrap('<div></div>');

		var wrapper = $(element).closest('div');

		wrapper.addClass('checker');

		$(element).on('change', function () {
			if (element.checked) {
				wrapper.addClass('active');
			} else {
				wrapper.removeClass('active');
			}
		});
	});

	// Radio
	$('input[type="radio"]:not(.unstyled)').each(function (index, element) {
		$(this).wrap('<div></div>');

		var wrapper = $(element).closest('div');

		wrapper.addClass('radio');

		$(element).on('change', function () {
			var name = element.getAttribute('name');

			$('[name="' + name + '"]').closest('div').removeClass('active');

			if (element.checked) {
				wrapper.addClass('active');
			} else {
				wrapper.removeClass('active');
			}
		});

		if (element.checked) {
			wrapper.addClass('active');
		} else {
			wrapper.removeClass('active');
		}
	});

	/**********************************************
	FORMS
	**********************************************/

	// Don't allow spaces in number type inputs
	$('input[type="number"]').on('keypress', function (e) {
		if (e.which === 32) {
			return false;
		}
	});

	/**********************************************
	ORDER FORM
	**********************************************/

	// Variables
	var $doorTypes = $('.js-order-door-type');
	var $selectedDoorType = $('.js-order-door-type:checked');
	var $doorFillings = $('.js-door-filling');
	var $doorWidths = $('.js-door-width');
	var $doorColorItems = $('.js-order-door-color-item');
	var $fieldTogglers = $('.js-order-field-toggle');

	// Show correct tab by the selected door type
	var $activeTab = $selectedDoorType.closest('.tab');
	$('#tab-control-' + $activeTab.attr('id')).click();

	// Controls for switching between the order steps
	$('.js-order-step-control').on('click', function () {
		$this = $(this);
		$hash = $this.data('show');
		$toShow = $($this.data('show'));
		$toHide = $($this.data('hide'));
		$toHide.fadeOut(200, function () {
			$toShow.fadeIn(200, function () {
				$(document).scrollTop($toShow.offset().top);
			});
		});
		if (history.pushState) {
			history.pushState(null, null, $hash);
		}
		else {
		  location.hash = $hash;
		}
		return false;
	});

	// Check hash on load
	$('.js-order-step-control').each(function() {
		if (window.location.hash == $(this).data('show')) {
			$(this).trigger('click');
		}
	});

	// Door colors selection
	$doorColorItems.on('click', function () {
		var $this = $(this);
		$doorColorItems.removeClass('active');
		$this.addClass('active');
		var $radiobutton = $this.children('input[type="radio"]');
		$radiobutton.prop('checked', true);
	});

	// Toggle available door fillings based on the selected door type
	$doorTypes.on('click', function () {
		var clickedFirst = false;
		var availableFillings = $(this).data('fillings');
		$doorFillings.each(function (index, item) {
			var $item = $(item);
			var id = $item.data('id');
			if (availableFillings.indexOf(id) === -1) {
				$item.prop('disabled', true);
				$item.closest('.cell').hide();
			} else {
				$item.prop('disabled', false);
				$item.closest('.cell').show();
				if (!clickedFirst) {
					$item.click();
					clickedFirst = true;
				}
			}
		});
	});

	// Toggle available door widths based on the selected door type
	$doorTypes.on('click', function () {
		var clickedFirst = false;
		var availableWidths = $(this).data('widths');
		$doorWidths.each(function (index, item) {
			var $item = $(item);
			var id = $item.data('id');
			if (availableWidths.indexOf(id) === -1) {
				$item.prop('disabled', true);
				$item.closest('.cell').hide();
			} else {
				$item.prop('disabled', false);
				$item.closest('.cell').show();
				if (!clickedFirst) {
					$item.click();
					clickedFirst = true;
				}
			}
		});
	});

	// Toggle available door widths based on the selected filling type
	$doorFillings.on('click', function () {
		var clickedFirst = false;
		var selectedType = $doorTypes.filter(':checked'),
			fillingId = $(this).data('id');
		if(selectedType.length) {
			var availableTypeWidths = selectedType.data('filling-widths'),
				availableWidths = availableTypeWidths[fillingId];

			$doorWidths.each(function (index, item) {
				var $item = $(item);
				var id = $item.data('id');
				if (availableWidths.indexOf(id) === -1) {
					$item.prop('disabled', true);
					$item.closest('.cell').hide();
				} else {
					$item.prop('disabled', false);
					$item.closest('.cell').show();
					if (!clickedFirst) {
						$item.click();
						clickedFirst = true;
					}
				}
			});
		}
	});

	// Show door colors by selected door type
	$doorTypes.on('click', function () {
		var doorType = $(this).data('id');
		var $doorColorItemsSelector = '.js-order-door-color-item';
		$($doorColorItemsSelector).hide(0, function () {
			var $doorTypeColors = $($doorColorItemsSelector + '.' + doorType);
			$doorTypeColors.first().click();
			$doorTypeColors.show();
		});
	});

	// Show correct door colors on first load
	$selectedDoorType.click();

	// Groups of fields toggling
	$fieldTogglers.on('change', function () {
		var $target = $('#' + $(this).data('content'));
		if ($target.length) {
			var isVisible = $target.is(':visible');
			$target.find('input').prop('disabled', !isVisible);
			$target.find('.js-order-price-chunk').trigger('change');
		}
	});

	// Set correct toggled groups of fields states
	$fieldTogglers.each(function () {
		var $this = $(this);
		if (!$this.is(':checked')) {
			var $target = $('#' + $(this).data('content'));
			$target.hide();
			$target.find('input').prop('disabled', true);
			$target.find('.js-order-price-chunk').trigger('change');
		}
	});

	// Recalculate the final price
	$('.js-order-price-chunk,.js-door-filling').on('change', function () {
		orderPriceRefresh();
	});

	// Order recalculation function
	function orderPriceRecalculate() {
		var priceType = $('.js-door-filling:checked').data('id');
		var priceAttribute = 'price-solid';
		if (priceType === 'door_filling_glazed') {
			priceAttribute = 'price-glazed';
		}
		var finalPrice = 0;
		$('.js-order-price-chunk').each(function () {
			$this = $(this);
			if ($this.is(':disabled')) {
				return true;
			}
			var fieldType = $this.attr('type');
			if (['radio', 'checkbox'].indexOf(fieldType) !== -1) {
				if ($this.is(':checked')) {
					finalPrice += parseInt($this.data(priceAttribute));
				}
			} else if (['text', 'number'].indexOf(fieldType) !== -1) {
				var count = parseInt($this.val());
				// Set reasonable orderable numbers
				if (count > 0) {
					finalPrice += parseInt($this.data(priceAttribute)) * count;
				}
			}
		});
		return finalPrice;
	}

	// Order price update function
	function orderPriceUpdate(price) {
		var $totalPriceLabel = $('.total-price .val');
		var $totalPriceHiddenInput = $('#order_form_totalPrice');
		var currency = $totalPriceLabel.data('currency');
		var finalPriceString = price.toLocaleString(document.documentElement.lang) + ' ' + currency;
		$totalPriceLabel.html(finalPriceString);
		$totalPriceHiddenInput.val(finalPriceString);
	}

	// Order total price refresh
	function orderPriceRefresh() {
		var totalPrice = orderPriceRecalculate();
		orderPriceUpdate(totalPrice);
	}

	// Refresh after page load
	orderPriceRefresh();

	// Door label change name
	$('[data-purpose="name_changer"]').on('keyup', function () {
		var val = $(this).val();

		$('[data-purpose="name_label"]').text(val);
	}).trigger('keyup');

	// Door label css
	$('.name-labels input[type="radio"]').on('change', function () {
		var radio = $(this);
		var item = radio.closest('.item');
		var name = radio.attr('name');

		if (radio.is(':checked')) {
			$('[name="' + name + '"]').closest('.item').removeClass('active');
			item.addClass('active');
		}
	});

	/**********************************************
	DROPZONE (FILE UPLOAD)
	**********************************************/

	if ($('.dropzone').length > 0) {
        var unique = $('#service_form_unique');
        var uniqueId = '';

        if (unique.length) {
            uniqueId = unique.val()
        }

		var myDropzone = new Dropzone('.dropzone', {
			maxFilesize: 10,
			addRemoveLinks: true,
			dictCancelUpload: "Zrušit",
			dictRemoveFile: "Smazat",
			dictCancelUploadConfirmation: "Zrušit nahrávání souboru?"
		});
		myDropzone.uploadedFileNames = [];
		myDropzone.updateUploadedFileNames = function () {
//			var uploadedFilenamesField = $('#order_form_uploadedFiles');
			var uploadedFilenamesField = $('.AJAX-formUploadFiles');

			if (uploadedFilenamesField.length) {
				uploadedFilenamesField.val(this.uploadedFileNames.join('/'));
			}
		}

        myDropzone.on('sending', function (file, xhr, formData) {
            formData.append('unique', uniqueId);
        });

		myDropzone.on('complete', function(file) {
			$('.dropzone').find('.dz-more').remove();
			$('.dropzone').append('<span class="dz-more">Kliknutím nebo přetažením nahrajte další soubor</span>');
		});

		myDropzone.on('drop', function(file) {
			$('.dropzone').find('.dz-more').remove();
		});

        var removeUrl = '/upload/form-order/remove';
        var removeUrlData = $('.dropzone').data('action-delete');

        if (removeUrlData !== undefined) {
            removeUrl = removeUrlData;
        }

        myDropzone.on('removedfile', function (file) {
			var self = this;
			var filename = file.name;

			$.ajax({
				url: removeUrl,
				type: 'POST',
				data: {
					filename: filename,
                    unique: uniqueId
				}
			}).always(function (response) {
				var uploadedFileName = response.serverFileName;

				self.uploadedFileNames = self.uploadedFileNames.filter(function (element) {
					element !== uploadedFileName;
				});
				self.updateUploadedFileNames();
			});
		});

		myDropzone.on('success', function(file, response, xhr) {
			var uploadedFileName = response.serverFileName;

			if (this.uploadedFileNames.indexOf(uploadedFileName) === -1) {
				this.uploadedFileNames.push(uploadedFileName);
			}
			this.updateUploadedFileNames();
		});

		myDropzone.on('maxfilesreached', function () {
			alert('Soubor je příliš velký, zmenšete jej, nebo nahrajte jiný, prosím');
		});

		myDropzone.on('error', function(file, response, xhr) {
			if (response !== undefined) {
				alert(response);
			}

			if (response.error !== undefined) {
				alert(response.error);
			}
		});
	}

	/**********************************************
	FIXER
	**********************************************/

	$(window).on('load scroll', function () {
		if ($('.fixer').length) {
			$('.fixer-start').each(function () {
				var scrollTop = $(window).scrollTop();
				var winH = $(window).height();
				var fixElWrapper = $(this);
				var fixEl = fixElWrapper.find('.fixer');
				var offset = 0;
				if (fixEl.attr('data-offset')) {
					var offset = fixEl.data('offset');
				}

				var fixElWrapperTop = fixElWrapper.offset().top + offset;

				var fixElHeight = fixEl.height();
				var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

				// Top panel
				if (scrollTop > fixElWrapperTop) {
					fixEl.addClass('fixed');

					// Hide in the end
					if (scrollTop > end) {
						fixEl.fadeOut();
					} else {
						fixEl.fadeIn();
					}
				} else {
					fixEl.removeClass('fixed');
				}
			});
		}
	});

	/**********************************************
	MOBILE NAV
	**********************************************/

	$(function () {
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');

		function showNav() {
			mobileNav.addClass('active');
			mobileNavOverlay.fadeIn();
			$('body').css({
				'overflow': 'hidden'
			});
			$('.wrap').addClass('active');
			$('.header').addClass('active');
			$('.nav-btn').addClass('active');
		}

		function hideNav() {
			mobileNav.removeClass('active');
			mobileNavOverlay.fadeOut();
			$('body').css({
				'overflow': 'auto'
			});
			$('.wrap').removeClass('active');
			$('.header').removeClass('active');
			$('.nav-btn').removeClass('active');
		}

		$('.nav-btn').on('click', function () {
			if (!mobileNav.hasClass('active')) {
				showNav();
			} else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').on('click', function () {
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').on('click', function () {
			hideNav();
		});

		$('.mobile-nav li a').each(function () {
			if ($(this).siblings('ul').length > 0) {
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav .sub-trigger').on('click', function () {
			var link = $(this);
			var li = link.closest('li');
			var subNav = li.find('.sub-nav');

			if (subNav.length > 0) {
				if (!subNav.hasClass('active')) {
					li.addClass('active');
					subNav.addClass('active');
				} else {
					li.removeClass('active');
					subNav.removeClass('active');
				}
			}

			return false;
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

	/**********************************************
	SIMPLE LIGHTBOX
	**********************************************/

	var simpleLightboxCaption = "Nahrávám&hellip;";

	var simpleLightboxSelectors = [
		'.lightbox',
		".product-img .point",
		"[data-purpose='play']"
	];

	simpleLightboxSelectors.forEach(function (selector) {
		new SimpleLightbox({
			elements: selector,
			loadingCaption: simpleLightboxCaption
		});
	});

	$("[data-gallery='lightbox']").each(function () {
		$(this).find(".img a").simpleLightbox({
			loadingCaption: simpleLightboxCaption
		});
	});

	/**********************************************
	SLIDERS
	**********************************************/

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-phablet') ? sliders[id]['slideNumPhablet'] = el.attr('data-slides-phablet') : sliders[id]['slideNumPhablet'] = sliders[id]['slideNumTablet'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumPhablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
			el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
			el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;
			el.attr('data-pause') ? sliders[id]['slidePause'] = el.data('pause') : sliders[id]['slidePause'] = 5000;
			el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';
			el.attr('data-customlink') ? sliders[id]['slideCustomlink'] = el.data('customlink') : sliders[id]['slideCustomlink'] = 'a.customLink';

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					effect: sliders[id]['slideEffect'],
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: sliders[id]['slideContinuous'],
					prevNext: sliders[id]['slideNav'],
					numeric: sliders[id]['slideNumeric'],
					slideCount: sliders[id]['slideNum'],
					speed: sliders[id]['slideSpeed'],
					responsive: true,
					customLink: sliders[id]['slideCustomlink'],
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev" arial-label="Předchozí"></a>',
					nextHtml: '<a href="#" class="next" arial-label="Další"></a>',
					initCallback: function () {
						sliders[id].slider.closest('.item-slider').addClass('active');
					}
				});
			}
		});

		$(window).on("load resizeEnd", function () {
			for (var id in sliders) {
				if (!sliders.hasOwnProperty(id))
					continue;

				if ($(sliders[id]['slider']).closest('.item-slider').hasClass('active')) {
					var width = $(window).width();
					var orgSlideCount = sliders[id]['slider'].getOption("slideCount");
					var slideCount;

					if (width >= 1200) {
						slideCount = sliders[id]['slideNum']; // 1200 - 1920
					} else if (width >= 992) {
						slideCount = sliders[id]['slideNumDesktop']; // 992 - 1199
					} else if (width >= 768) {
						slideCount = sliders[id]['slideNumTablet']; // 768 - 991
					} else if (width >= 576) {
						slideCount = sliders[id]['slideNumPhablet']; // 576 - 768
					} else {
						slideCount = sliders[id]['slideNumMobile']; // 0 - 575
					}
					if (slideCount != orgSlideCount) {
						sliders[id]['slider'].setOption("slideCount", slideCount);
					}

					// Show/hide nav
					var slider = $(sliders[id]['slider']).closest('.item-slider');
					var slideCount = sliders[id]['slider'].getOption('slideCount');
					var slides = $('.panel', slider).length;

					if (slides <= slideCount) {
						slider.addClass('hide-nav');
					} else {
						slider.removeClass('hide-nav');
					}
				}
			};
		});
	}

	/**********************************************
	NOTIFICATION(S)
	**********************************************/

	function setNotificationCookie(id) {
		$.cookie(id, 'true', { path: '/', expires: 1 })
	}

	function getNotificationCookie(id) {
		return $.cookie(id);
	}

	// Set a notification cookie (for a day)
	$('.js-notification-set-cookie, .js-notification-content a').click(function() {
		var $notification = $(this).closest('.js-notification');
		if ($notification.length && $notification.attr('id')) {
			setNotificationCookie($notification.attr('id'))
		}
	});

	// If there is no cookie set, show the notification(s)
	$('.js-notification').each(function() {
		var $notification = $(this);
		var id = $notification.attr('id');
		if (!id) {
			return;
		}
		var cookieValue = getNotificationCookie(id);
		if (cookieValue === 'true') {
			return;
		}
		if ($notification.hasClass('js-notification-strip')) {
			$notification.show();
		}
		if ($notification.hasClass('js-notification-modal')) {
			$notification.addClass('active');
			SimpleLightbox.open({
				content: document.getElementById(id),
				closeBtnClass: 'slbCloseModalBtn',
				beforeClose: function () {
					setNotificationCookie(id);
					$notification.removeClass('active');
				}
			});
		}
	});

	/**********************************************
	NOTIFICATION: STRIP
	**********************************************/

	// Close the notification
	$('body').on('click', '.js-notification .js-close', function () {
		$(this).closest('.js-notification').fadeOut();
		return false;
	});

});

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Scrollspy
(function ($) {
	$.fn.scrollSpy = function (settings) {
		settings = jQuery.extend({
			showSpeed: 100,
			callback: function () {}
		}, settings);

		var topMenu = $(this);

		if (topMenu.length) {
			// Cache selectors
			var lastId,
				topMenuHeight = topMenu.outerHeight(),

				// All list items
				menuItems = topMenu.find("a"),

				// Anchors corresponding to menu items
				scrollItems = menuItems.map(function () {
					var item = $($(this).attr("href"));
					if (item.length) {
						return item;
					}
				});

			// Bind to scroll
			$(window).bind('load scroll', function () {
				// Get container scroll position
				var fromTop = $(this).scrollTop() + topMenuHeight + 50;

				// Get id of current scroll item
				var cur = scrollItems.map(function () {
					if ($(this).offset().top < fromTop)
						return this;
				});
				// Get the id of the current element
				cur = cur[cur.length - 1];
				var id = cur && cur.length ? cur[0].id : "";

				if (lastId !== id) {
					lastId = id;
					// Set/remove active class
					menuItems
						.parent().removeClass("active")
						.end().filter("[href*=\\#" + id + "]").parent().addClass("active");
				}
			});
		}
	}
})(jQuery);

// Univerzální show/hide
(function ($) {
	$.fn.toggler = function (settings) {

		settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false,
			historyPushState: false
		}, settings);

		$(this).each(function (index, value) {

			var trigger = $(this);
			var targetSelector = '#' + trigger.data('content');
			var content = $(targetSelector);

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					if ($(this).is(':checked')) {
						content.fadeIn(settings.showSpeed);
					} else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					if (trigger.is(':checked')) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				function scrollToContent() {
					var offset = 0;
					if (settings.offsetScroll) {
						offset = settings.offsetScroll
					}
					$("html:not(:animated),body:not(:animated)").animate({
						scrollTop: $(content).offset().top + offset
					}, 500);
				}

				function scrollToTrigger() {
					var offset = 0;
					if (settings.offsetScroll) {
						offset = settings.offsetScroll + 200
					}
					$("html:not(:animated),body:not(:animated)").animate({
						scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset
					}, 500);
				}

				trigger.on('click', function (event) {
					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								};
							});
						} else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								};
							});
						}

						$(this).addClass('active').find('.text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					} else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								};
							});
						} else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								};
							});
						}
						$(this).removeClass('active').find('.text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}
					if (settings.historyPushState) {
						history.pushState(null, null, targetSelector);
					}
					event.preventDefault();
					//return false;
				});
			}
			// Hide on document click
			if (settings.hideOnDocumentClick || trigger.attr('data-hideonclick') == 'true') {
				$(document).on('click', function (e) {
					if ((!$(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) && (!trigger.is(e.target) && trigger.has(e.target).length === 0)) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
			// Escape button
			if (settings.hideOnEscape) {
				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						content.fadeOut();
						trigger.removeClass('active');
					}
				});
			}
		});
	}
})(jQuery);

// Plynulý scroll ke kotvě
(function ($) {
	$.fn.scrollToAnchor = function (settings) {

		settings = jQuery.extend({
			speed: 750
		}, settings);

		return this.each(function () {
			var caller = this
			$(caller).click(function (event) {
				event.preventDefault()
				var locationHref = window.location.href
				var elementClick = $(caller).attr("href")

				var destination = $(elementClick).offset().top;

				$("html:not(:animated),body:not(:animated)").animate({
					scrollTop: destination
				}, {
					duration: settings.speed,
					//easing: "easeInOutQuint",
					complete: function () {
						history.pushState(null, null, elementClick);
					}
				});
				return false;
			});
		});
	}
})(jQuery);

// Lite tabs (záložkové menu)
(function ($) {
	$.fn.liteTabs = function (options) {
		return this.each(function () {
			var defaults = {
				fadeIn: false,
				height: 'auto',
				hideHash: false,
				hashTag: true,
				selectedTab: 1,
				width: 500
			};

			// Merge defaults with options in new settings object
			var settings = $.extend({}, defaults, options);

			// Define key variables
			var $this = $(this);
			var $ul = $this.find('.tab-nav ul');
			var $tab = $ul.find('a');
			var $div = $this.find('.tab-content').first().find('.tab');

			// Tab click
			$tab.click(function (e) {
				var hash = this.hash;
				var id = hash.replace('#', '');
				var selectedPanel = $div.filter('[id="' + id + '"]');
				var filterHash = $div.removeClass('selected').filter('[id="' + id + '"]');

				// defaults: add selected class to tab
				$tab.removeClass('selected').filter(this).addClass('selected');

				// Rodičovská záložka zůstane otevřená při kliku na vnořené záložky v záložce
				var parentId = $tab.filter(this).closest('.tab').attr('id');

				$('.tab[id="' + parentId + '"]').addClass('selected');
				$('.tab-nav a[href="' + parentId + '"]').addClass('selected');

				if ($tab.parents('.tabs').length > 1) {
					e.preventDefault();
				}

				// Show panel
				filterHash.addClass('selected');

				// Option: hide hash change
				if (settings.hideHash) {
					e.preventDefault();
				}

				return false;
			});

			// Option: set selected tab
			if (settings.selectedTab) {
				$tab.eq(settings.selectedTab - 1).addClass('selected');
				$div.eq(settings.selectedTab - 1).addClass('selected');
			}

			// If hash tag..
			var hash = window.location.hash.substring(1);
			$('[href="#' + hash + '"]').click();
		});
	};
})(jQuery);
