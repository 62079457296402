document.addEventListener('DOMContentLoaded', () => {
	let count = 0;
	const maxCount = 530;
	const message = document.getElementById('service_form_message');
	const counter = document.getElementById('letter-count');

	if (!message || !counter) {
		return;
	}

	counter.textContent = count + '/' + maxCount;

	message.addEventListener('input', (e) => {
		count = message.value.length;

		if (count > maxCount) {
			message.value = message.value.substr(0, maxCount);
			count = message.value.length;
		}

		counter.textContent = count + '/' + maxCount;
	});
});

// Auto complete for address
$(document).ready(() => {
	$('#service_form_street').autocomplete({
		minChars: 2,
		deferRequestBy: 300,
		lookup: async (query, done) => {
			const formatEdgeValue = (edge) => {
				let formattedValue = edge.streetAndNumber;
				if (edge.city) formattedValue += `, ${edge.city}`;
				if (edge.zip) formattedValue += `, ${edge.zip}`;
				return formattedValue;
			}

			const result = await $.gql.getAddress(query, (edge) => ({
				value: formatEdgeValue(edge),
				data: edge,
			}));

			done({
				suggestions: result || [],
			});
		},
		onSelect: ({ data: { street, number, city, zip } }) => {
			$('#service_form_street').val(street);
			$('#service_form_houseNumber').val(number).focus();
			$('#service_form_city').val(city);
			$('#service_form_postalCode').val(zip);
		}
	});
});
