$(document).ready(() => {
	$.gql = {
		execute: async (query, url = window.FE_APP.backendCoreGQLUri) => {
			return new Promise((resolve, reject) => {
				$.ajax({
					url,
					method: 'POST',
					data: JSON.stringify(query),
					dataType: 'json',
					success: resolve,
					error: reject
				});
			});
		},
		getAddress: async (address, dataMapper) => {
			const result = await $.gql.execute(
				{
					query: `query GetAddress($address: String!) {
  getAddress(address: $address) {
    totalCount
    edges {
      id
      number
      streetAndNumber
      street
      city
      municipality
      district
      region
      zip
      wholeAddress
      __typename
    }
    __typename
  }
}`,
					variables: {
						address,
					}
				});

			let data = result.data?.getAddress || null;

			if (dataMapper && data !== null) {
				data = data.edges.map(dataMapper);
			}

			return data;
		},
	}
});
