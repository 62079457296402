jQuery(function(){
	initFlashMessages();
});

function initFlashMessages() {
	Array.from(document.querySelectorAll('.flash-message__close')).forEach(function(closeBtn,i) {
		closeBtn.addEventListener('click', function() {
			const fm = closeBtn.closest('.flash-message');
			fm.classList.add('flash-message--hidden');
		});
	});

	const flashMessages = document.querySelectorAll('.flash-message');
	if(flashMessages.length) {
		setTimeout(function() {
			Array.from(flashMessages).forEach(function(fm,i) {
				if(fm.getAttribute('data-hide')) {
					fm.classList.add('flash-message--hidden');
				}
			});
		}, 5000);
	}
}